import React from "react"
import Header from "../components/Header"
import { Container } from "../components/Sections"
import { FaceFrown } from "../components/Icons"

function PageNotFound() {
  return (
    <>
      <Header heading="Seite nicht gefunden" subheading="und das ist alles, was ich dazu sagen kann..." />
      <Container className="max-w-7xl">
        <div className="w-100 my-10 text-center">
          <FaceFrown className="w-48 h-48 mx-auto stroke-gray-300 dark:stroke-gray-400"/>
          <div className="mx-auto mb-6 text-7xl text-gray-300 dark:text-gray-400">
            404
          </div>
        </div>
      </Container>
    </>
  )
}

export default PageNotFound
